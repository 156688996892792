<template>
	<form @submit.prevent="onHandleSubmiForm">
		<slot></slot>

		<template v-if="hasSlot('button')">
			<slot name="button"></slot>
		</template>
		<div v-else :class="classAction">
			<ui-button class="p-button-danger me-2" v-if="form && form.hash" @click="onHandleDeleteItem" label="Usuń pozycję"></ui-button>
			<ui-button class="p-button-success" type="submit" :label="button"></ui-button>
		</div>

		<template v-if="hasSlot('footer')">
			<slot name="footer"></slot>
		</template>
	</form>
</template>

<script>
import axios from 'axios';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
import { useRoute, useRouter } from 'vue-router';

export default {
	name: 'FormBody',
	props: {
		button: {
			type: String,
			default: 'Zapisz',
		},
		async: {
			type: Boolean,
			default: false,
		},
		method: {
			type: String,
			default: 'post',
		},
		hash: String,
		action: String,
		form: Object,
		error: Object,
		callback: Function,
		errorMessage: String,
		successMessage: String,
		fields: Object,
		classAction: {
			type: String,
			default: 'd-flex mt-4 justify-content-end',
		},
	},
	emits: ['error'],
	setup(props, { emit, slots }) {
		const toast = useToast();
		const confirm = useConfirm();
		const route = useRoute();
		const router = useRouter();

		const hasSlot = (name) => !!slots[name];

		const onHandleDeleteItem = async () => {
			confirm.require({
				message: 'Czy napewno chcesz usunąć tą pozycję? Pamiętaj, że ta czynność jest nieodwracalna.',
				header: 'Potwierdź usunięcie',
				icon: 'pi pi-info-circle',
				acceptClass: 'p-button-danger',
				acceptLabel: 'Tak, usuń',
				rejectLabel: 'Nie, pozostaw',
				accept: async () => {
					await axios.delete(`${props.action}/${props.form.hash}`);
					const name = route.name.replace('Update', 'Index');
					router.push({ name });
					toast.add({
						severity: 'success', detail: 'Pozycja została usunięta', life: 3000,
					});
				},
			});
		};

		const onHandleSubmiForm = async () => {
			const type = props.method;
			try {
				emit('update:error', []);
				const request = await axios[type](props.action, props.form);
				const { data } = request;

				if (props.callback !== undefined) {
					if (props.async) {
						await props.callback(data);
					} else {
						props.callback(data);
					}
				} else if (data) {
					const name = route.name.replace('Create', 'Update');
					if (data.hash) {
						router.push({ name, params: { hash: data.hash } });
					}
					toast.add({
						severity: 'success', detail: type === 'put' ? 'Pozycja została zaktualizowana' : 'Pozycja została dodana', life: 3000,
					});
				}
			} catch (err) {
				const { response } = err;
				const { status, data } = response;
				if (status === 422) {
					emit('update:error', data.errors);
					toast.add({
						severity: 'error', summary: 'Uwaga!', detail: 'Popraw oznaczone pola w formularzy, aby zapisać pozycję', life: 3000,
					});
				} else if (status) {
					toast.add({
						severity: 'error', summary: 'Uwaga!', detail: props.errorMessage || `Wystąpił błąd. Spróbuj ponownie (Status błędu ${status})`, life: 3000,
					});
				}
			}
		};

		return {
			hasSlot,
			onHandleSubmiForm,
			onHandleDeleteItem,
		};
	},
};
</script>
