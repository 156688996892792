<template>
	<div v-if="count > 1">
		Placówki
		<template v-for="item in items" :key="item.id">
			<input-checkbox :label="item.name" />
		</template>
	</div>
</template>

<script setup>
import { useFacility } from '@/composables/useFacility';
import { computed, onMounted } from 'vue';

const { items, onLoadItems } = useFacility();
const count = computed(() => Object.keys(items.value).length);

onMounted(() => {
	onLoadItems();
});
</script>

<script>
export default {
	name: 'InputFacility',
};
</script>
