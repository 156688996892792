import axios from 'axios';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useUserStore } from '@/store/useUserStore';
import { useNotificationStore } from '@/store/useNotificationStore';
import { storeToRefs } from 'pinia';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		component: () => import('../layouts/AppLayout.vue'),
		meta: {
			auth: true,
		},
		children: [
			{
				path: '',
				name: 'Dashboard',
				component: () => import('../views/Dashboard/DefaultView.vue'),
			},
			{
				path: 'access-denied',
				name: 'accessDenied',
				component: () => import('../views/AccessDenied/DefaultView.vue'),
			},
			{
				path: 'message',
				component: () => import('../views/Message/DefaultView.vue'),
				children: [
					{
						path: '',
						name: 'messageIndex',
						component: () => import('../views/Message/IndexView.vue'),
					},
					{
						path: ':hash',
						name: 'messageShow',
						component: () => import('../views/Message/IndexView.vue'),
					},
					{
						path: 'create',
						name: 'messageCreate',
						component: () => import('../views/Message/CreateView.vue'),
					},
				],
			},
			{
				path: 'offer',
				meta: { breadcrumb: 'Moja szkoła' },
				component: () => import('../views/Offer/DefaultView.vue'),
				children: [
					{
						path: 'language',
						meta: { parent: 'language', breadcrumb: 'Języki' },
						component: () => import('../views/Language/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'languageIndex',
								component: () => import('../views/Language/IndexView.vue'),
							},
							{
								path: 'create',
								name: 'languageCreate',
								meta: { breadcrumb: 'Utwórz język' },
								component: () => import('../views/Language/CreateView.vue'),
							},
							{
								path: ':hash',
								name: 'languageUpdate',
								meta: { breadcrumb: 'Edycja języka' },
								component: () => import('../views/Language/UpdateView.vue'),
							},
						],
					},
					{
						path: 'course',
						meta: { parent: 'course', breadcrumb: 'Kursy' },
						component: () => import('../views/Course/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'courseIndex',
								component: () => import('../views/Course/IndexView.vue'),
							},
							{
								path: 'create',
								name: 'courseCreate',
								meta: { breadcrumb: 'Utwórz' },
								component: () => import('../views/Course/CreateView.vue'),
							},
							{
								path: ':hash',
								name: 'courseUpdate',
								meta: { breadcrumb: 'Edytuj kurs' },
								component: () => import('../views/Course/UpdateView.vue'),
							},
						],
					},
					{
						path: 'groups',
						meta: { parent: 'groups', breadcrumb: 'Grupy' },
						component: () => import('../views/Groups/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'groupsIndex',
								component: () => import('../views/Groups/IndexView.vue'),
							},
							{
								path: 'create',
								name: 'groupsCreate',
								meta: { breadcrumb: 'Utwórz grupę' },
								component: () => import('../views/Groups/CreateView.vue'),
							},
						],
					},
					{
						path: 'camp',
						meta: { parent: 'camp', breadcrumb: 'Kolonie, półkolonie' },
						component: () => import('../views/Camp/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'campIndex',

								component: () => import('../views/Camp/IndexView.vue'),
							},
							{
								path: 'create',
								name: 'campCreate',
								meta: { breadcrumb: 'Utwórz kolonię, półkolonię' },
								component: () => import('../views/Camp/CreateView.vue'),
							},
						],
					},
					{
						path: 'lector',
						meta: { parent: 'lector' },
						component: () => import('../views/Lector/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'lectorIndex',
								component: () => import('../views/Lector/IndexView.vue'),
							},
							{
								path: 'create',
								name: 'lectorCreate',
								component: () => import('../views/Lector/CreateView.vue'),
							},
							{
								path: ':hash',
								name: 'lectorUpdate',
								component: () => import('../views/Lector/UpdateView.vue'),
							},
						],
					},
					{
						path: 'student',
						meta: { breadcrumb: 'Kursanci' },
						component: () => import('../views/Student/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'studentIndex',
								component: () => import('../views/Student/IndexView.vue'),
							},
							{
								path: 'create',
								name: 'studentCreate',
								meta: { breadcrumb: 'Utwórz kursanta' },
								component: () => import('../views/Student/CreateView.vue'),
							},
							{
								path: ':hash',
								name: 'studentUpdate',
								meta: { breadcrumb: 'Edytuj kursanta' },
								component: () => import('../views/Student/UpdateView.vue'),
							},
						],
					},
					{
						path: 'company',
						meta: { parent: 'company' },
						component: () => import('../views/Company/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'companyIndex',
								component: () => import('../views/Company/IndexView.vue'),
							},
							{
								path: 'create',
								name: 'companyCreate',
								component: () => import('../views/Company/CreateView.vue'),
							},
						],
					},
					{
						path: 'agreement',
						meta: { parent: 'agreement' },
						component: () => import('../views/Agreement/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'agreementIndex',
								component: () => import('../views/Agreement/IndexView.vue'),
							},
						],
					},
					{
						path: 'library',
						meta: { parent: 'library' },
						component: () => import('../views/Agreement/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'libraryIndex',
								component: () => import('../views/Agreement/IndexView.vue'),
							},
						],
					},
					{
						path: 'storage',
						meta: { parent: 'storage', breadcrumb: 'Menadżer plików' },
						component: () => import('../views/Storage/DefaultView.vue'),
						children: [
							{
								path: ':hash?',
								name: 'driveIndex',
								component: () => import('../views/Storage/IndexView.vue'),
							},
						],
					},
					{
						path: 'task',
						meta: { parent: 'task' },
						component: () => import('../views/Task/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'task',
								component: () => import('../views/Task/IndexView.vue'),
							},
							{
								path: 'today',
								name: 'taskToday',
								component: () => import('../views/Agreement/IndexView.vue'),
							},
							{
								path: 'week',
								name: 'taskWeek',
								component: () => import('../views/Agreement/IndexView.vue'),
							},
							{
								path: 'overdue',
								name: 'taskOverdue',
								component: () => import('../views/Agreement/IndexView.vue'),
							},
							{
								path: 'project',
								name: 'taskProject',
								component: () => import('../views/Agreement/IndexView.vue'),
							},
							{
								path: 'directory',
								name: 'taskDirectory',
								component: () => import('../views/Agreement/IndexView.vue'),
							},
							{
								path: ':hash',
								name: 'taskShow',
								component: () => import('../views/Task/ShowView.vue'),
							},
						],
					},
				],
			},
			{
				path: 'schedule',
				meta: { breadcrumb: 'Harmonogramy' },
				component: () => import('../views/Schedule/DefaultView.vue'),
				children: [
					{
						path: 'attendance-log',
						component: () => import('../views/AttendanceLog/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'attendanceLogIndex',
								component: () => import('../views/AttendanceLog/IndexView.vue'),
							},
						],
					},
					{
						path: 'timetable',
						component: () => import('../views/Timetable/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'timetableIndex',
								component: () => import('../views/Timetable/IndexView.vue'),
							},
						],
					},
					{
						path: 'calendar',
						component: () => import('../views/Calendar/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'scheduleCalendarIndex',
								component: () => import('../views/Calendar/IndexView.vue'),
							},
						],
					},
					{
						path: 'holidays',
						component: () => import('../views/Holidays/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'holidaysIndex',
								component: () => import('../views/Holidays/IndexView.vue'),
							},
						],
					},
				],
			},
			{
				path: 'learning',
				meta: { breadcrumb: 'Nauka' },
				component: () => import('../views/Learning/DefaultView.vue'),
				children: [
					{
						path: 'housework',
						component: () => import('../views/Housework/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'houseworkIndex',
								component: () => import('../views/Housework/IndexView.vue'),
							},
						],
					},
					{
						path: 'flashcards',
						component: () => import('../views/Flashcards/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'flashcardsIndex',
								component: () => import('../views/Flashcards/IndexView.vue'),
							},
						],
					},
					{
						path: 'tests',
						component: () => import('../views/Tests/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'testsIndex',
								component: () => import('../views/Tests/IndexView.vue'),
							},
						],
					},
				],
			},
			{
				path: 'finance',
				meta: { breadcrumb: 'Finanse' },
				component: () => import('../views/Learning/DefaultView.vue'),
				children: [
					{
						path: 'invoice',
						component: () => import('../views/Invoice/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'invoiceIndex',
								component: () => import('../views/Invoice/IndexView.vue'),
							},
						],
					},
					{
						path: 'rate-lector',
						component: () => import('../views/RateLector/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'rateLectorIndex',
								component: () => import('../views/RateLector/IndexView.vue'),
							},
						],
					},
					{
						path: 'pricelist',
						component: () => import('../views/Pricelist/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'pricelistIndex',
								component: () => import('../views/Pricelist/IndexView.vue'),
							},
						],
					},
					{
						path: 'vindication',
						component: () => import('../views/Vindication/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'vindicationIndex',
								component: () => import('../views/Vindication/IndexView.vue'),
							},
						],
					},
				],
			},
			{
				path: 'user',
				meta: { breadcrumb: 'Uzytkownicy' },
				component: () => import('../views/Users/DefaultView.vue'),
				children: [
					{
						path: 'privileges',
						component: () => import('../views/Privileges/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'privilegesIndex',
								component: () => import('../views/Privileges/IndexView.vue'),
							},
							{
								path: 'create',
								name: 'privilegesCreate',
								component: () => import('../views/Privileges/CreateView.vue'),
							},
							{
								path: ':hash',
								name: 'privilegesUpdate',
								component: () => import('../views/Privileges/UpdateView.vue'),
							},
						],
					},
					{
						path: 'users',
						component: () => import('../views/Users/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'usersIndex',
								component: () => import('../views/Users/IndexView.vue'),
							},
						],
					},
					{
						path: 'activity-log',
						component: () => import('../views/ActivityLog/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'activityLog',
								component: () => import('../views/ActivityLog/IndexView.vue'),
							},
						],
					},
				],
			},
			{
				path: 'report',
				meta: { breadcrumb: 'Rapoert' },
				component: () => import('../views/Report/DefaultView.vue'),
				children: [
					{
						path: 'frequency',
						component: () => import('../views/Frequency/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'reportFrequency',
								component: () => import('../views/Frequency/IndexView.vue'),
							},
						],
					},
					{
						path: 'finance',
						component: () => import('../views/Finance/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'reportFinance',
								component: () => import('../views/Finance/IndexView.vue'),
							},
						],
					},
				],
			},
			{
				path: 'setting',
				meta: { breadcrumb: 'Ustawienia' },
				component: () => import('../views/Setting/DefaultView.vue'),
				children: [
					{
						path: 'facility',
						meta: { parent: 'facility' },
						component: () => import('../views/Facility/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'facilityIndex',
								component: () => import('../views/Facility/IndexView.vue'),
							},
							{
								path: 'create',
								name: 'facilityCreate',
								component: () => import('../views/Facility/CreateView.vue'),
							},
						],
					},
					{
						path: 'field',
						meta: { parent: 'field' },
						component: () => import('../views/Field/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'fieldIndex',
								component: () => import('../views/Field/IndexView.vue'),
							},
							{
								path: ':hash',
								name: 'fieldUpdate',
								component: () => import('../views/Field/UpdateView.vue'),
							},
						],
					},
					{
						path: 'semestr',
						component: () => import('../views/Semestr/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'semestrIndex',
								component: () => import('../views/Semestr/IndexView.vue'),
							},
						],
					},
					{
						path: 'age-group',
						component: () => import('../views/AgeGroup/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'ageGroupIndex',
								component: () => import('../views/AgeGroup/IndexView.vue'),
							},
							{
								path: 'create',
								name: 'ageGroupCreate',
								component: () => import('../views/AgeGroup/CreateView.vue'),
							},
							{
								path: ':hash',
								name: 'ageGroupUpdate',
								component: () => import('../views/AgeGroup/UpdateView.vue'),
							},
						],
					},
					{
						path: 'type-group',
						component: () => import('../views/TypeGroup/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'typeGroupIndex',
								component: () => import('../views/TypeGroup/IndexView.vue'),
							},
						],
					},
					{
						path: 'duration',
						component: () => import('../views/Duration/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'durationIndex',
								component: () => import('../views/Duration/IndexView.vue'),
							},
						],
					},
					{
						path: 'message-template',
						component: () => import('../views/MessageTemplate/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'messageTemplateIndex',
								component: () => import('../views/MessageTemplate/IndexView.vue'),
							},
						],
					},
					{
						path: 'package-sms',
						component: () => import('../views/PackageSms/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'packageSmsIndex',
								component: () => import('../views/PackageSms/IndexView.vue'),
							},
						],
					},
					{
						path: 'subscription',
						component: () => import('../views/Subscription/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'subscriptionIndex',
								component: () => import('../views/Subscription/IndexView.vue'),
							},
						],
					},
					{
						path: 'payments',
						meta: { breadcrumb: 'Płatności' },
						component: () => import('../views/Payments/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'paymentsIndex',
								component: () => import('../views/Payments/IndexView.vue'),
							},
						],
					},
					{
						path: 'email',
						component: () => import('../views/Email/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'emailIndex',
								component: () => import('../views/Email/IndexView.vue'),
							},
						],
					},
					{
						path: 'invoices',
						component: () => import('../views/Invoices/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'invoicesIndex',
								component: () => import('../views/Invoices/IndexView.vue'),
							},
						],
					},
					{
						path: 'template-agreement',
						meta: { breadcrumb: 'Szablony umów' },
						component: () => import('../views/TemplateAgreement/DefaultView.vue'),
						children: [
							{
								path: '',
								name: 'templateAgreementIndex',
								component: () => import('../views/TemplateAgreement/IndexView.vue'),
							},
							{
								path: 'create',
								meta: { breadcrumb: 'Utwórz szablon umowy' },
								name: 'templateAgreementCreate',
								component: () => import('../views/TemplateAgreement/CreateView.vue'),
							},
							{
								path: ':hash',
								meta: { breadcrumb: 'Edycja szablonu umowy' },
								name: 'templateAgreementUpdate',
								component: () => import('../views/TemplateAgreement/UpdateView.vue'),
							},
						],
					},
				],
			},
			{
				path: 'todo',
				component: () => import('../views/Todo/DefaultView.vue'),
				children: [
					{
						path: '',
						name: 'todoIndex',
						component: () => import('../views/Todo/IndexView.vue'),
					},
					{
						path: ':hash',
						name: 'todoShow',
						component: () => import('../views/Todo/ShowView.vue'),
					},
				],
			},
			{
				path: 'notification',
				component: () => import('../views/Notification/DefaultView.vue'),
				children: [
					{
						path: '',
						name: 'notificationIndex',
						component: () => import('../views/Notification/IndexView.vue'),
					},
					{
						path: ':hash',
						name: 'notificationShow',
						component: () => import('../views/Notification/ShowView.vue'),
					},
				],
			},
			{
				path: 'account',
				meta: { breadcrumb: 'Moje konto' },
				component: () => import('../views/Account/DefaultView.vue'),
				children: [
					{
						path: '',
						name: 'accountIndex',
						meta: { breadcrumb: 'Moje dane' },
						component: () => import('../views/Account/IndexView.vue'),
					},
					{
						path: 'access',
						name: 'accountAccess',
						meta: { breadcrumb: 'Dane do logowania' },
						component: () => import('../views/Account/AccessView.vue'),
					},
					{
						path: 'setting',
						name: 'accountSetting',
						meta: { breadcrumb: 'Ustawienia konta' },
						component: () => import('../views/Account/SettingView.vue'),
					},
					{
						path: 'log',
						name: 'accountLog',
						meta: { breadcrumb: 'Historia mojej aktywności' },
						component: () => import('../views/Account/LogView.vue'),
					},
				],
			},
		],
	},
	{
		path: '/auth',
		component: () => import('../layouts/AuthLayout.vue'),
		meta: {
			authLayout: true,
		},
		children: [
			{
				path: 'login',
				name: 'authLogin',
				component: () => import('../views/Auth/LoginView.vue'),
			},
			{
				path: 'register',
				name: 'authRegister',
				component: () => import('../views/Auth/RegisterView.vue'),
			},
			{
				path: 'reset',
				name: 'authReset',
				component: () => import('../views/Auth/ResetView.vue'),
			},
		],
	},
];

const hasAccessToModule = async (to: any) => {
	const request = await axios.post('/module/access', to);
	return request.data;
};

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach(async (to, from, next) => {
	const storeUser = useUserStore();
	const notificationStore = useNotificationStore();

	const { authenticated } = storeToRefs(storeUser);

	if (localStorage.getItem('auth._token') !== undefined && localStorage.getItem('auth._token') !== null) {
		if (!authenticated.value) {
			try {
				const requestUser = await axios.get('/user');
				const { data } = requestUser;
				const token = localStorage.getItem('auth._token');

				storeUser.setAuthenticated(true);
				storeUser.setUser(data.user);
				storeUser.setToken(token);
				storeUser.setIdSchool(data.id_school);
				storeUser.setIdUserRole(data.id_user_role);
				storeUser.setWorkspaces(data.workspaces);
				storeUser.setModule(data.module);

				if (data.id_school) {
					axios.defaults.headers.common['Id-School'] = data.id_school;
				}

				if (data.id_user_role) {
					axios.defaults.headers.common['Id-User-Role'] = data.id_user_role;
				}

				if (to.matched.some((record) => record.meta.authLayout)) {
					next({ path: '/' });
				}

				const hasAccess = await hasAccessToModule(to);
				if (!hasAccess) {
					next('/access-denied');
				}
				(async () => {
					await notificationStore.getNotification();
				})();
			} catch (err: any) {
				const { response } = err;
				const { status } = response;
				if (status === 401) {
					localStorage.removeItem('auth._token');
					window.location.reload();
				}
			}
		} else if (to.name !== 'accessDenied') {
			const hasAccess = await hasAccessToModule({ name: to.name });
			if (!hasAccess) {
				next('/access-denied');
			}
		}
	} else if (to.matched.some((record) => record.meta.auth)) {
		next('/auth/login');
		console.log(3);
	}

	next();
});

export default router;
