<template>
	<input-field>
		<div class="form__colorpicker">
			<div class="d-flex align-items-center">
				<ColorPicker v-bind="$attrs" v-model="color" @change="setColor" />
				<input v-model="color" class="color" maxlength="7" />
			</div>
		</div>
	</input-field>
</template>

<script setup>
import ColorPicker from 'primevue/colorpicker';
import InputField from '@/components/Input/Field.vue';
import { onMounted, ref, watch } from 'vue';

const props = defineProps({
	modelValue: String,
});
const emit = defineEmits(['modelValue']);
const color = ref(null);

const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
onMounted(() => {
	if (props.modelValue === undefined || props.modelValue === '') {
		color.value = randomColor;
	} else {
		color.value = props.modelValue;
	}
});
watch(() => color.value, () => {
	if (color.value.length === 6) {
		color.value = `#${color.value}`;
	}
	emit('update:modelValue', color.value);
});

</script>

<script>
export default {
	name: 'InputColor',
};
</script>
