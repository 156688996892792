import axios from 'axios';
import { ref } from 'vue';

export function useFacility() {
	const items = ref(<any[]>([]));
	const form = ref({
		name: '',
		primary: false,
		address: {
			street: '',
			street_no: '',
			local_no: '',
			zip_code: '',
			town: '',
		},
	});
	const error = ref({});

	const onLoadItems = async () => {
		try {
			const request = await axios.get('setting/facility');
			items.value = request.data;
		} catch (e) {
			console.error(e);
		}
	};

	const onChangePrimary = async (hash: string) => {
		try {
			await axios.put(`setting/facility/primary/${hash}`);

			items.value.forEach((item, key) => {
				const facility = items.value[key];
				const status = facility.id === hash || false;
				facility.primary = status;
			});
		} catch (e) {
			console.error(e);
		}
	};

	const selected = ref([]);

	return {
		onLoadItems,
		onChangePrimary,
		items,
		selected,
		form,
		error,
	};
}
