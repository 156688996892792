<template>
	<div class="dropdown">
		<button class="btn btn-light btn-action dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
			<i class="material-icons-outlined text-muted">settings</i>
		</button>
		<ul class="dropdown-menu dropdown-menu-end icon-dropdown" aria-labelledby="dropdownMenuButton1">
			<li v-if="update">
				<router-link class="dropdown-item" :to="{ name: update, params: { hash: slotProps.data.hash } }">Edytuj</router-link>
			</li>
			<li v-if="duplicate">
				<a class="dropdown-item" href="#">Duplikuj</a>
			</li>
			<template v-if="remove">
				<li><hr class="dropdown-divider"></li>
				<li>
					<span class="dropdown-item text-danger" @click="removeItem" @keydown="removeItem">Usuń</span>
				</li>
			</template>
		</ul>
	</div>
</template>

<script setup>
import axios from 'axios';
import { useConfirm } from 'primevue/useconfirm';

const props = defineProps({
	slotProps: Object,
	update: String,
	duplicate: String,
	remove: String,
	onLoadItems: Function,
});

const confirm = useConfirm();
const removeItem = () => {
	confirm.require({
		message: 'Czy napewno chcesz usunąć tą pozycję? Pamiętaj, że ta czynność jest nieodwracalna.',
		header: 'Potwierdź usunięcie',
		icon: 'pi pi-exclamation-triangle',
		acceptClass: 'p-button-danger',
		acceptLabel: 'Tak, usuń',
		rejectLabel: 'Nie, pozostaw',
		accept: async () => {
			const route = `${props.remove}/${props.slotProps.data.hash}`;
			await axios.delete(route);
			if (props.onLoadItems !== undefined) {
				await props.onLoadItems();
			}
		},
	});
};
</script>

<script>
export default {
	name: 'TableAction',
};
</script>
